.aboutus {
    gap: 50px;
    padding: 50px 50px !important;
    justify-content: space-between;
    align-items: center;
}

.aboutusInfo {
    width: 55%;
}

.aboutus_desc {
    margin-top: 50px !important;
    font-weight: 600;
    font-family: 'Montserrat Alternates', sans-serif;
    text-align: left;

}

.aboutus_heading {
    border-left: 4px solid #3e186d;
    width: max-content;
    text-align: left;
    padding-left: 10px;
    margin: 0;
}

.aboutus_heading h1 {
    font-size: 3rem;
    font-weight: 600;
}

.aboutus_heading p {
    font-weight: 600;
    font-size: 1.4rem;
    margin-top: -5px !important;
}

.player-holder {
    height: 400px;
    max-width: 60%;
    text-align: center;
}

.player-thumb {
    width: 400px;
    height: 400px;
    display: inline-block;
    border-radius: 50%;
    background-image: url('../../../public/images/aboutus.jpg');
    background-size: cover;
    /* background-position: center; */
    border: 5px solid #3e186d;
}

@media (max-width: 950px) {
    .aboutus_desc {
        text-align: center;

    }

    .aboutus {
        flex-direction: column !important;
        align-items: left;
        padding: 50px 10px !important;
    }

    .aboutusInfo {
        width: 100%;
    }

    .player-holder {
        max-width: 100%;
    }
}

@media (max-width: 650px) {
    .player-thumb {
        width: 300px;
        height: 300px;

    }

}