fieldset {
    border: 3px solid;
    border-color: white;
    padding: 0px 15px;
    width: fit-content;
}

fieldset legend {
    font-weight: 700;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 1.5rem;
}

.banner {
    width: 100%;
    background-color: rgb(187, 6, 6);
    color: white;
    justify-content: space-around;
    position: relative;
    align-items: center;
    padding: 0px 40px 0px 40px;
}

.banner>img {
    width: 90px;
    height: 90px;
    position: absolute;
    bottom: -40px;
}

.banner_leftimg {
    left: 10%;
}

.banner_rightimg {
    right: 10%;
}

.banner>div {
    align-items: center;
    gap: 20px;

}

.banner h1 {
    font-size: 3rem;
    font-weight: 700;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}


.special_offer {
    border: 1px solid white;
    padding: 5px;
}


@media (max-width: 1300px) {
    .banner>div {
        flex-direction: column !important;
        gap: 10px;

    }
}

@media (max-width: 900px) {
    .banner>div {
        flex-direction: column !important;
        gap: 10px;

    }

    .banner h1 {
        font-size: 1.5rem;
    }

    fieldset {
        border: 1px solid white;
        padding: 0px 30px;
    }

    fieldset legend {
        font-size: 1rem;

    }

}

@media (max-width: 468px) {
    .banner {
        padding: 0px 10px 0px 10px;
    }

    .banner h1 {
        font-size: 1rem;
    }

    fieldset {
        width: fit-content;
    }

    fieldset legend {
        font-size: 0.7rem !important;
    }


}