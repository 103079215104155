#sidebar {
    width: 0;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.2);
    position: absolute;
    top: 0;
    left: 0%;
    z-index: 100;
}

#sidebar_show {
    width: 0;
    position: absolute;
    left: 250px;
    height: 100%;
}

.update {
    cursor: pointer;
}

.update img {
    border-radius: 50%;
    border: solid grey;
    width: 50px;
    clip-path: circle();
}

#blogs_updates {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: white;
    background-image: linear-gradient(to right top, #140d3f, #1a0e43, #200f48, #26104c, #2c1050, #2f1156, #31135d, #341463, #32186e, #2d1c7a, #232186, #0f2692);
    overflow-x: hidden;
    transition: 0.3s;
    padding-top: 60px;
    gap: 10px;

    /* justify-content: center; */
    align-items: center;
}

#blogs_updates h2 {
    color: white;
}

#blogs_updates .closebtn {
    position: absolute;
    color: white;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
    text-decoration: none;
}

#blogs_updates .update {
    /* margin: auto !important; */
    width: 90%;
    border-radius: 10px;
    padding: 2px;
    background-color: white;
    color: black;
    gap: 20px;
    padding: 5px;
}

.update_title {
    font-size: 0.7rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}