.btn:focus,
.btn:active {
    outline: none !important;
    box-shadow: none;
}

.testimonials_arrow {
    width: 80px;
    height: 80px;
}

.testimonials_cards_row .prev {
    text-align: start;
}

.testimonials_cards_row .next {
    text-align: end;
}

.testimonial_card {
    border: 1px solid #ddd;
    border-radius: 6px;
    max-width: 350px;
    text-align: center;
    margin-top: 60px !important;
    background-image: linear-gradient(to right top, #140d3f, #1a0e43, #200f48, #26104c, #2c1050, #2f1156, #31135d, #341463, #32186e, #2d1c7a, #232186, #0f2692);
}

.testimonial_heading {
    font-size: 4rem;
    font-weight: 700;
}

.card_img {
    width: 120px;
    height: 120px;
    overflow: hidden;
    border-radius: 100%;
    margin: -60px auto 0 !important;
}

.card_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.card_info {
    padding: 10px 25px;
    color: white;
    height: 300px;
}



.info_desc p::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
}


.info_desc p {
    max-height: 200px;
    overflow-y: scroll;
}


.card_info h5 {
    margin-top: 10px !important;
}

.testimonial {
    display: flex;
    justify-content: center;
    text-align: center;
}

.testimonials_cards_row {
    justify-content: center;
    gap: 14px;
}