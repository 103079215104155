.services_main {
    background-image: url('../../../public/icons/services/service-background.jpg');
    padding: 100px 50px;
    background-repeat: no-repeat;
    background-size: cover;
}

.services_main_heading {
    color: white;
    width: 80%;
    margin: auto !important;
    gap: 30px;
    margin-bottom: 30px !important;
}

.services_Offered {
    flex-wrap: wrap;
    gap: 100px;
    justify-content: center;
}

.service_card {
    height: fit-content;
    width: 20rem;
}

.readmore {
    text-decoration: underline !important;
    color: rgb(75, 8, 138) !important;
    cursor: pointer;
}

.card {
    padding: 5px;
    border-radius: 20px;
    cursor: pointer;
}

.card-img-top {
    border-radius: 20px;
    height: 220px;
}

.card-title {
    color: rgb(75, 8, 138);
    font-size: 1.5rem;
    font-weight: 600;
}

.card_title {
    border-bottom: 1px solid rgb(75, 8, 138);
    width: 20%;
    margin: auto !important;
    margin-bottom: 10px !important;
}

.card-text {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    white-space: normal;
    text-align: center;
}

.testimonial_modal_card {
    position: relative;
    border-radius: 10px;
    width: 100%;
    text-align: center;
    margin: auto !important;
    height: fit-content;
    padding: 20px 40px;
    background-color: white;
}

.testimonial_modal_image {
    background-color: #341055 !important;
}

.testimonial_modal_card .close {
    position: absolute;
    right: 10px;
    top: 10px;
}

.testimonial_modal_heading {
    font-weight: 600;
    padding-bottom: 20px
}

.getquote {
    width: fit-content;
    margin: auto !important;
    background-image: url('../../../public/icons/services/service-background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    border-radius: 20px;
    margin-top: 10px !important;
    cursor: pointer;
    border: none;
    padding: 5px 10px;
}

.modal-content {
    margin-bottom: 10% !important;
}

.QR_image {
    height: auto;
    width: auto;
    /* object-fit: contain; */
}

.closeQr {
    cursor: pointer;
}

@media (max-width: 900px) {
    .services_main_heading {
        width: 100%;
    }

    .services_main {
        padding: 50px 20px;

    }
}