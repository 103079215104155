.new_section {
  border-radius: 30px;
  border-top: 2px solid #3e186d;
  padding-top: 20px;

}

hr {
  margin-top: 20px !important;
}

.text-heading {
  color: #3e186d;
  font-size: 3rem;
}

.text-content {
  color: #656465;
}



.App {
  text-align: center;
  overflow-x: hidden;
}

* {
  margin: 0 !important;
  font-family: 'Montserrat', sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.totop {
  position: fixed;
  bottom: 20px;
  right: 10px;
  z-index: 100;
  width: 40px;
  height: 40px;
  padding: 0;
  justify-content: center;
}

.totop img {
  width: 30px;
  height: 30px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}